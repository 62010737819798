/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
import patientInfo from "./patient-info"
import riskProcedure from "./risk-procedure"
import preOp from "./pre-op"
import intraOp from "./intra-op"
import postOp from "./post-op"
import discharge from "./discharge"
import followUp from "./follow-up"

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [...patientInfo, ...riskProcedure, ...preOp, ...intraOp, ...postOp, ...discharge, ...followUp]
